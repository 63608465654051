import * as Yup from 'yup';

import { STRING_LOCALE } from './string-validators';
import { NUMBER_LOCALE } from './number-validators';
import { MIXED_LOCALE } from './mixed-validators';
import { ARRAY_LOCALE } from './array-validators';

export const setFormLocalization = () =>
  Yup.setLocale({
    string: STRING_LOCALE, // eslint-disable-line id-denylist,
    number: NUMBER_LOCALE, // eslint-disable-line id-denylist,
    mixed: MIXED_LOCALE,
    array: ARRAY_LOCALE,
  });
